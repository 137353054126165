<template>
  <div class="container">
    <div class="search-box mb10">
      <van-search v-model="keyword" placeholder="搜一搜，找到你想要的班级" />
    </div>
    <div class="main">
      <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
        <div class="list">
          <div v-for="item in list" :key="item.class_id" class="list-item" @click="onNavigator({path:'/teacher/classes/detail?class_id='+item.class_id})">
            <div class="list-item-hd">
              <div class="list-item-title">{{ item.class_name }}</div>
              <div class="list-item-title">班级人数：{{ item.class_member_count }} / {{ item.class_member_limit }}</div>
              <div class="list-item-txt">开课次数： {{ item.class_had_course_count }}</div>
              <div class="list-item-txt">班级课程： {{ item.course_cate_name }}</div>
              <div class="list-item-txt">授授课校区： {{ item.studio_name }}</div>
            </div>
            <div class="list-item-arrow">详情<van-icon name="arrow" /></div>
            <div class="list-item-ft">
              <van-button type="primary" size="small">{{ item.status_name }}</van-button>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>

import { Search } from 'vant'
import PageMixin from '@/mixins/page'
import { debounce } from '@/common/util'

export default {
  name: 'TeacherClasses',
  components: {
    [Search.name]: Search
  },
  mixins: [PageMixin],
  data() {
    return {
      keyword: '',
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1
    }
  },
  watch: {
    keyword(val) {
      this.search()
    }
  },
  created() {
    // this.onListLoad()
  },
  methods: {
    onListLoad() {
      this.onPage()
    },
    search: debounce(function() {
      this.pageCurrent = 1
      this.onPage()
    }, 300),
    onPage() {
      this.$api.employee_classes({ q: this.keyword, 'per-page': this.pageSize, page: this.pageCurrent }).then(res => {
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
        this.pageCurrent += 1
      })
    }
  }
}
</script>
<style lang="less" scoped>

  .tab{
    margin-bottom: 10px;
  }
  .list{

  }
  .list-item{
    box-sizing: border-box;
    position: relative;
    background: #fff;
    padding: 0 15px;
    margin-bottom: 10px;
    &-hd{
      padding: 10px 0;
      font-size: 14px;
      color: #333;
    }
    &-bd{
      position: relative;
      padding: 10px 0;
      border-top: 1PX solid @ui-hairline-color;
      border-bottom: 1PX solid @ui-hairline-color;
    }
    &-ft{
      position: absolute;
      bottom: 20px;
      right: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-arrow{
      position: absolute;
      top: 19px;
      right: 15px;
      color: #999;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-self: start;
    }
    &-title{
      font-size: 14px;
      color: #333;
      margin-bottom: 5px;
    }
    &-txt{
      font-size: 14px;
      color: #999;
    }
    &-btn{
        position: absolute;
        top: 20px;
        right: 10px;
      }
  }
</style>

